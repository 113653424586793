import React from 'react';
import {Col} from 'react-bootstrap';
import HomePageUpperSection from '../../components/homePageUpperSection';
import HomePageServices from '../../components/homePageServices';
import Section from '../../components/section';
import Carousel from '../../components/carusel/slider';
import UsecasesSection from '../../components/usecases-secttion';
import HomePageIntegrations from '../../components/homePageIntegration';
import testimonials from '../../images/testimonials/testimonials.png';
import illustration1 from '../../images/testimonials/illustration-1.png';
import illustration2 from '../../images/testimonials/illustration-2.png';
import './styles.scss';

export default () => {
  return (
    <>
      <HomePageUpperSection />
      <HomePageServices />
      <Section className="pt-5 px-3 blue testimonials">
        <div className="radial"></div>
        <img src={illustration1} className="illustration1" alt="" />
        <img src={illustration2} className="illustration2" alt="" />
        <Col className="section-part">
          <h2 className="text-center mb-0 f_size_38 f_700 w_color l_height50">
            Trusted by over 130,000 <br />
            <span className="blue_color">businesses of all sizes.</span>
          </h2>
          <img
            src={testimonials}
            alt="Trusted by over 130,000 businesses of all sizes."
            className="mt-4 mx-auto trusted-img d-none d-md-flex"
            width={1022}
            height={694}
            style={{maxWidth: '1022px', width: '100%', height: 'auto'}}
          />
          <div className="d-md-none d-flex ">
            <Carousel />
          </div>
        </Col>
      </Section>
      <UsecasesSection />
      <HomePageIntegrations />
    </>
  );
};
