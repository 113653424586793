import React from 'react';
import Section from '../../components/section';
import monitorWebsite from '../../images/use-cases/monitor-website.png';
import newProduct from '../../images/use-cases/new-product.png';
import trackPrices from '../../images/use-cases/track-prices.png';
import availability from '../../images/use-cases/availability.png';
import recruitment from '../../images/use-cases/recruitment.png';
import getTheDeal from '../../images/use-cases/get-the-deal.png';
import firstToMarket from '../../images/use-cases/first-to-market.png';
import customerReview from '../../images/use-cases/customer-reviews.png';
import security from '../../images/use-cases/webiste-security.png';
import compliance from '../../images/use-cases/compliance.png';
import property from '../../images/use-cases/property.png';
import searchResults from '../../images/use-cases/search-results.png';
import currency from '../../images/use-cases/currency.png';
import employeeReview from '../../images/use-cases/employee-review.png';
import backlink from '../../images/use-cases/backlink.png';
import outreach from '../../images/use-cases/outreach.png';

import './styles.scss';

export default () => {
  return (
    <>
      <Section className="pt-5 pb-xl-4 px-3">
        <div className="d-block d-xl-flex">
          <div className="left-container">
            <div className="mr-4">
              <h2 className="f_size_38 f_700 t_color2 l_height50 text-center text-xl-left">
                Change Monitoring <br className="d-none d-xl-flex" />
                <span className="blue_color">For Your Business</span>
              </h2>
              <p className="f_size_18 t_color4 text-center text-xl-left">
                Find out how different industries are using Hexowatch to be the first to know when important changes
                affect their business.
              </p>
            </div>

            <div className="d-block d-xl-flex mt_60">
              <div className="use-case-card sm mt-auto">
                <div className="row row-reverse-md">
                  <div className="col-12 col-sm-6 col-xl-12 my-auto">
                    <div className="use-case-title">Get notified when something looks wrong</div>
                    <div className="use-case-text">
                      Check how your website, branding and content renders across different devices and get instant
                      alerts when something goes wrong.
                    </div>
                  </div>

                  <div className="d-flex col-12 col-sm-6 col-xl-12 mt-xl-4">
                    <img src={monitorWebsite} alt="Notification alert for errors" />
                  </div>
                </div>
              </div>
              <div className='mt-auto'>
                <div className="use-case-card">
                  <div className="row">
                    <div className="col-12 col-sm-6 m-auto">
                      <div className="use-case-title">Stay ahead of the game</div>
                      <div className="use-case-text">
                        Be the first to know when a competitor launches a new product or cancels a product line.
                      </div>
                    </div>

                    <div className="col-12 col-sm-6 d-flex ">
                      <img src={newProduct} alt="Person staying ahead in planning" />
                    </div>
                  </div>
                </div>
                <div className="use-case-card">
                  <div className="row row-reverse row-reverse-xl">
                    <div className="col-12 col-sm-6 m-auto">
                      <div className="use-case-title">Track prices in your market</div>
                      <div className="use-case-text">
                        Ensure your price is always competitive by tracking competitor, partner and distributor pricing
                        across multiple channels.
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 d-flex">
                      <img src={trackPrices} alt="Graph tracking market prices" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="right-container">
            <div className="use-case-card">
              <div className="row row-reverse-xl">
                <div className="col-12 col-sm-6 m-auto">
                  <div className="use-case-title">Get alerted when product availability changes</div>
                  <div className="use-case-text">
                    Are you waiting for a product to go back in stock? Or want to be the first to secure a car
                    reservation or limited edition sneaker? Get alerted instantly when a product page changes.
                  </div>
                </div>
                <div className="col-12 col-sm-6 d-flex">
                  <img src={availability} alt="Alert for product availability change" />
                </div>
              </div>
            </div>
            <div className="use-case-card">
              <div className="row row-reverse-md">
                <div className="col-12 col-sm-6 m-auto">
                  <div className="use-case-title">Recruitment opportunity alerts</div>
                  <div className="use-case-text">
                    Ideal for recruiters or job seekers, monitor the vacancies of companies to want to work or track
                    vacancies from the most popular job search engines.
                  </div>
                </div>

                <div className="col-12 col-sm-6 d-flex">
                  <img src={recruitment} alt="Recruitment opportunity notification" />
                </div>
              </div>
            </div>
            <div className="use-case-card">
              <div className="row">
                <div className="col-12 col-sm-6 m-auto">
                  <div className="use-case-title">Get the deal first</div>
                  <div className="use-case-text">
                    Looking to snap up a real deal?
                    <br />
                    Monitor product prices and get alerts when the price drops.
                  </div>
                </div>

                <div className="col-12 col-sm-6 d-flex">
                  <img src={getTheDeal} alt="Price drop alert for products" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Section lightGrey className="p-0 d-none d-xl-block">
        <div style={{width: '100%', height: '20px'}}></div>
      </Section>
      <Section className="pt-xl-4 pb-xl-4 px-3">
        <div className="w-100">
          <div className="d-block d-xl-flex">
            <div className="use-case-card mw-450 mr-5">
              <div className="row row-reverse-md">
                <div className="col-12 col-sm-6 m-auto">
                  <div className="use-case-title">Keep eye on property opportunities</div>
                  <div className="use-case-text">
                    Get alerted instantly when a new property goes on sale that matches your requirements by monitoring
                    real estate agents or aggregator sites.
                  </div>
                </div>

                <div className="col-12 col-sm-6 d-flex">
                  <img src={property} alt="Watching property opportunities" />
                </div>
              </div>
            </div>
            <div className="use-case-card mw-450">
              <div className="row">
                <div className="col-12 col-sm-6 m-auto">
                  <div className="use-case-title">Defacement & tamper protection</div>
                  <div className="use-case-text">
                    Take your website security to the next level and make sure nobody changes the content of your
                    website without your knowledge.
                  </div>
                </div>

                <div className="col-12 col-sm-6 d-flex">
                  <img src={security} alt="Protection against tampering" />
                </div>
              </div>
            </div>
          </div>
          <div className="d-block d-xl-flex justify-content-end w-100">
            <div className="use-case-card mw-450 mr-5">
              <div className="row row-reverse-md">
                <div className="col-12 col-sm-6 m-auto">
                  <div className="use-case-title">Get customer review alerts</div>
                  <div className="use-case-text">
                    Get alerts when customers leave new feedback on popular review websites.
                  </div>
                </div>

                <div className="col-12 col-sm-6 d-flex">
                  <img src={customerReview} alt="Customer review notifications" />
                </div>
              </div>
            </div>
            <div className="use-case-card mw-450">
              <div className="row">
                <div className="col-12 col-sm-6 m-auto">
                  <div className="use-case-title">Be first to market</div>
                  <div className="use-case-text">
                    Get alerts when your supplier or dropshipper launches new product lines.
                  </div>
                </div>

                <div className="col-12 col-sm-6 d-flex">
                  <img src={firstToMarket} alt="Alert for new supplier products" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Section lightGrey className="p-0 d-none d-xl-block">
        <div style={{width: '100%', height: '20px'}}></div>
      </Section>
      <Section className="pt-xl-4 pb-5 px-3">
        <div className="d-block d-xl-flex w-100">
          <div>
            <div className="use-case-card">
              <div className="row row-reverse-md">
                <div className="col-12 col-sm-6 m-auto">
                  <div className="use-case-title">Cloud archiving for legal and compliance</div>
                  <div className="use-case-text">
                    Every time a page gets changed, we archive a snapshot you can access at any time for legal and
                    compliance purposes.
                  </div>
                </div>

                <div className="col-12 col-sm-6 d-flex">
                  <img src={compliance} alt="Cloud archiving for compliance" />
                </div>
              </div>
            </div>
            <div className="use-case-card">
              <div className="row row-reverse-xl">
                <div className="col-12 col-sm-6 m-auto">
                  <div className="use-case-title">Track search results changes on news sites</div>
                  <div className="use-case-text">
                    Be the first to know when you make news, or when your competitors issue a press release.
                  </div>
                </div>
                <div className="col-12 col-sm-6 d-flex">
                  <img src={searchResults} alt="Tracking news site search results" />
                </div>
              </div>
            </div>
            <div className="use-case-card">
              <div className="row row-reverse row-reverse-xl">
                <div className="col-12 col-sm-6 m-auto">
                  <div className="use-case-title">Track currency or market index values</div>
                  <div className="use-case-text">
                    Keep track of market fluctuations by monitoring currencies or financial instruments and get alerted
                    when values change or hit a threshold.
                  </div>
                </div>
                <div className="col-12 col-sm-6 d-flex">
                  <img src={currency} alt="Monitoring currency and market index" />
                </div>
              </div>
            </div>
          </div>
          <div className="my-auto">
            <div className="use-case-card">
              <div className="row row-reverse-xl">
                <div className="col-12 col-sm-6 m-auto">
                  <div className="use-case-title">Employee review alerts</div>
                  <div className="use-case-text">
                    Get alerts when employees leave new feedback on popular review websites.
                  </div>
                </div>
                <div className="col-12 col-sm-6 d-flex">
                  <img src={employeeReview} alt="Alerts for employee reviews" />
                </div>
              </div>
            </div>
            <div className="use-case-card mw-450">
              <div className="row row-reverse-md">
                <div className="col-12 col-sm-6 m-auto">
                  <div className="use-case-title">Backlinks tracking for SEO</div>
                  <div className="use-case-text">
                    Don’t lose your link juice, check if your SEO backlinks are present on guest posts or client
                    websites.
                  </div>
                </div>
                <div className="col-12 col-sm-6 d-flex">
                  <img src={backlink} alt="Tracking SEO backlinks" />
                </div>
              </div>
            </div>
          </div>
          <div className='my-auto'>
            <div className="use-case-card sm">
              <div className="row">
                <div className="col-12 col-sm-6 col-xl-12 my-auto">
                  <div className="use-case-title">Get the perfect outreach ice breaker</div>
                  <div className="use-case-text">
                    Monitor your prospects websites for any tech changes or new updates to spark a conversion.
                  </div>
                </div>

                <div className="d-flex col-12 col-sm-6 col-xl-12 mt-xl-4">
                  <img src={outreach} alt="Perfect outreach ice breaker alert" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
};
