import React from "react";
import { Link } from "gatsby";
import "./styles.scss";

const HomePageCards = (props: any) => {
  return (
    <div className="text-center mb-20 mt_40">
      <h2 className="f_size_38 f_700 t_color2 l_height50 mb-30">
        Be the first to know <span className="blue_color">when important<br/> changes happen</span>
      </h2>
      <p className="text-center f_size_18 t_color4">With 13 monitoring types, Hexowatch makes it easy to stay informed when any page changes<br/> are detected - at scale.</p>
      <div className="row mt_60">
        {props.cards.map((item: any) => {
          return (
            <>
            {item.name.includes('RSS') ? <div className="d-none d-lg-block col-12 col-md-6 col-lg-4"></div> : null}
            <div
              key={item.name}
              className={`col-12 col-md-6 col-lg-${props.lgCol || 4}`}
            >
              <div className="card_home">
                <div className={`card-body text-center ${item.name.toLowerCase().replace(' monitoring', '').replace(' ', '-')}`}>
                  {item.link ? (
                    <Link to={item.link}>
                      {props.svg ? (
                        item.icon
                      ) : (
                        <img src={item.icon} alt={item.description} />
                      )}
                      <h3 className="bold-text mt-4 mb-2">{item.name}</h3>
                      <p>{item.description}</p>
                    </Link>
                  ) : (
                    <>
                      {props.svg ? (
                        item.icon
                      ) : (
                        <img src={item.icon} alt={item.description} />
                      )}
                      <h3 className="bold-text mt-4 mb-2">{item.name}</h3>
                      <p>{item.description}</p>
                    </>
                  )}
                </div>
                {/* {item.name === "Visual monitoring" ||
                item.name === "Content monitoring" ||
                item.name === "HTML element monitoring" ? (
                  <span className="sidebar__link-badge badge badge-secondary">
                    <span>New</span>
                  </span>
                ) : null} */}
              </div>
            </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default HomePageCards;
