import React, {useState, useEffect, useRef} from 'react';
import {CarouselItems} from './carouselItems';
import Testimonial1 from '../../../images/testimonials/testimonial-1.png';
import Testimonial2 from '../../../images/testimonials/testimonial-2.png';
import Testimonial3 from '../../../images/testimonials/testimonial-3.png';
import Testimonial4 from '../../../images/testimonials/testimonial-4.png';
import Testimonial5 from '../../../images/testimonials/testimonial-5.png';
import Testimonial6 from '../../../images/testimonials/testimonial-6.png';

import {dot} from '../../../images/svg-icons';

import './styles.scss';

interface User {
  id: number;
  icon: string;
}

const items: User[] = [
  {
    id: 1,
    icon: Testimonial1,
  },
  {
    id: 2,
    icon: Testimonial2,
  },
  {
    id: 3,
    icon: Testimonial3,
  },
  {
    id: 4,
    icon: Testimonial4,
  },
  {
    id: 5,
    icon: Testimonial5,
  },
  {
    id: 6,
    icon: Testimonial6,
  },
];

export const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const [touching, setTouching] = useState(false);
  const [autoLoop, setAutoLoop] = useState(true);

  const innerRef = useRef<HTMLDivElement>(null);

  const updateIndex = (newIndex: number) => {
    if (newIndex < 0) {
      newIndex = items.length - 1;
    } else if (newIndex >= items.length) {
      newIndex = 0;
    }
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (autoLoop && !touching) {
      interval = setInterval(() => {
        updateIndex(activeIndex + 1);
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [activeIndex, touching, autoLoop]);

  const handleTouchStart = () => {
    setTouching(true);
    setAutoLoop(false);
  };

  const handleTouchEnd = () => {
    setTouching(false);
    setAutoLoop(true);
  };

  const translateXValue = `translateX(-${activeIndex * 100}%)`;

  return (
    <div className="carousel" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
      <div className="inner" style={{transform: translateXValue}} ref={innerRef}>
        {items.map(item => {
          return <CarouselItems key={item.id} item={item} />;
        })}
        <CarouselItems key={items[0].id} item={items[0]} />
      </div>

      <div className="carousel-buttons">
        <div className="indicators">
          {items.map((item, index) => {
            return (
              <button
                className="indicator-buttons"
                onClick={() => {
                  updateIndex(index);
                }}
              >
                <span className={`${index === activeIndex && 'active-dot'}`}>{dot}</span>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default Carousel;
