import React, {FC} from 'react';

interface User {
  item: {
    id: number;
    icon: string;
  };
}

export const CarouselItems: FC<User> = ({item}) => {
  return (
    <div className="items">
      <img className="carousel-img" src={item.icon} />
    </div>
  );
};
