import React from 'react';
import {Link} from 'gatsby';
import Section from '../section';

import slack from '../../images/integrations/home-page-integrations/slack.svg';
import telegram from '../../images/integrations/home-page-integrations/telegram.svg';
import google from '../../images/integrations/home-page-integrations/google.svg';
import googleSheets from '../../images/integrations/home-page-integrations/google-sheets.svg';
import zapier from '../../images/integrations/home-page-integrations/zapier.svg';
import pabbly from '../../images/integrations/home-page-integrations/pabbly-connect.svg';
import webhook from '../../images/integrations/home-page-integrations/webhook.svg';
import discord from '../../images/integrations/home-page-integrations/discord.svg';
import teams from '../../images/integrations/home-page-integrations/ms-teams.svg';
import api from '../../images/integrations/home-page-integrations/api.svg';
import illustration1 from '../../images/integrations/illustration-1.png';
import illustration2 from '../../images/integrations/illustration-2.png';

import './styles.scss';

const HomePageIntegrations = () => {
  const points = [
    {
      name: 'Telegram',
      logo: telegram,
      linkTo: '/telegrambot',
    },
    {
      name: 'Slack',
      logo: slack,
      linkTo: '/slackbot',
    },
    {
      name: 'Gmail',
      logo: google,
    },
    {
      name: 'Google Sheets',
      logo: googleSheets,
    },
    {
      name: 'Zapier',
      logo: zapier,
      linkTo: '/zapier',
    },
    {
      name: 'Pabbly Connect',
      logo: pabbly,
      linkTo: '/pabbly-connect',
    },
    {
      name: 'Webhook',
      logo: webhook,
      linkTo: '/webhook',
    },
    {
      name: 'Discord',
      logo: discord,
      linkTo: '/discord',
    },
    {
      name: 'API',
      logo: api,
      linkTo: '/api-documentation',
    },
    {
      name: 'MS Teams',
      logo: teams,
      linkTo: '/microsoft-teams',
    },
  ];

  return (
    <Section className="blue py-5 integrations">
      <div className="col-12 d-block d-md-none mb-4">
        <h2 className="f_size_38 f_700 w_color l_height50 my-auto text-center">
          Software <span className="blue_color">Integrations</span>
        </h2>
      </div>
      <img src={illustration1} className="illustration1" alt="" />
      <img src={illustration2} className="illustration2" alt="" />
      <div className="col-12 col-md-7">
        <div className="d-flex flex-wrap w-100 justify-content-center" style={{maxWidth: '500px', margin: 'auto'}}>
          {points.map(item => {
            return (
              <div className="integration-item p-2" key={item.name}>
                {item.linkTo ? (
                  <Link to={item.linkTo || ''}>
                    <img src={item.logo} width={44} height={44} alt={item.name} />
                  </Link>
                ) : (
                  <>
                    <img src={item.logo} width={44} height={44} alt={item.name} />
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="col-5 d-none d-md-flex">
        <h2 className="f_size_38 f_700 w_color l_height50 my-auto">
          Software
          <br /> <span className="blue_color">Integrations</span>
        </h2>
      </div>
    </Section>
  );
};

export default HomePageIntegrations;
