import React from 'react';
import {navigate} from 'gatsby';
import {Col, Row} from 'react-bootstrap';
import Button from '../../components/button';
import UserWrap from '../userWrap';
import {openCalendly} from '../../helpers/trackingEvents';
import dash from '../../images/hexowatch-home.png';
import capterra from '../../images/ratings/capterra.png';
import getApp from '../../images/ratings/getapp.png';
import productHunt from '../../images/ratings/hunt.png';
import g2 from '../../images/ratings/g2.png';
import business1 from '../../images/ratings/airmeet.svg';
import business2 from '../../images/ratings/manpowergroup.svg';
import business3 from '../../images/ratings/coinmetrics.svg';
import business4 from '../../images/ratings/rankmath.svg';
import btnArrow from '../../images/btn-arrow.svg';

import './styles.scss';

const ratings = [
  {
    name: 'GetApp',
    image: getApp,
    value: '4.8/5',
    linkTo: 'https://www.getapp.com/website-ecommerce-software/a/hexometer/',
  },
  {
    name: 'Capterra',
    image: capterra,
    value: '4.8/5',
    linkTo: 'https://www.capterra.com/p/198619/Hexometer/reviews/',
  },
  {
    name: 'Product Hunt',
    image: productHunt,
    value: '5/5',
    linkTo: 'https://www.producthunt.com/posts/hexometer-2',
  },
  {
    name: 'G2.com',
    image: g2,
    value: '4.7/5',
    linkTo:
      'https://www.g2.com/products/hexometer/reviews?__cf_chl_jschl_tk__=3ca982c5f6b76f56b7dcfffe3923c0af659ca984-1599738892-0-ASvYCSGB6_EWtBYuu_eeUv8D-dp1P0Gf0eQC7jJ8acorMP5lItkuFXnmC2p3dTm16uAi_QmIBoZ-hjhQLg-0LCKQmZ2CjCWpYXCBHFdcymv7rQZRXjVy3iOj4zSqPcVNFfm5duH-yxRucpbWpJs0n3wEPxCqEIyL3ATSkQUb1fMrEUcMuRDdKYKXLfl01CuuaOzkrNzi5Euaf8uRKBsdeONTJ2ScShlIhTxsTW-C-vBn7eN21qysxCh-zmJ3UFRhJWmegWUEy-2kuWPUEA5-pXVaaZaNkB1AI4gRMYIWYUcF_2mDZojx5-Kx5kNTtPtJkA#survey-response-4360759',
  },
];

const HomePageUpperSection = () => {
  return (
    <>
      <section id="seo_home_area" className="seo_home_area with-bg">
        <div className="container_home">
          <div className="row_home pb-3">
            <Row className="m-auto justify-content-center">
              <Col lg={6} className="center text-wrapper">
                <h1 className="f_size_38 f_700 l_height50 w_color">
                  Website{' '}
                  <span className="t_color2" style={{border: '2px dashed #050038', borderRadius: '10px', padding: '0 5px', display: 'inline-block'}}>
                    Change
                  </span>{' '}
                  Detection,
                  <br />
                  Monitoring & Archiving
                </h1>
                <div className="w_color f_size_16">
                  Never miss a beat. Monitor any page for visual, content, source code, technology, availability, or
                  price changes without lifting a finger.
                </div>
                <div className="d-flex d-lg-none w-100">
                  <img
                    src={dash}
                    alt="Website Change Detection, Monitoring & Archiving"
                    className="m-auto"
                    style={{maxWidth: '100%'}}
                  />
                </div>
                <div className="home-page-cta-container mx-auto mx-lg-0" style={{maxWidth: '80%'}}>
                  <div className="position-relative w-100 w-md-50 mx-auto">
                    <UserWrap
                      withoutUser={() => (
                        <Button primary className="trial_btn w-100" onClick={() => navigate('/registration')}>
                          {/* <Translate name="HOME_FREE_TRIAL_BTN" /> */}
                          Get started in minutes
                        </Button>
                      )}
                      withLoading={() => (
                        <Button primary className="trial_btn w-100" onClick={() => navigate('/registration')}>
                          {/* <Translate name="HOME_FREE_TRIAL_BTN" /> */}
                          Get started in minutes
                        </Button>
                      )}
                      withUser={() => (
                        <Button
                          primary
                          className="trial_btn w-100"
                          onClick={() =>
                            (window.location.href = window.location.host.includes('37.186.119.181')
                              ? 'http://37.186.119.181:3276'
                              : 'https://dash.hexowatch.com/hexowatch')
                          }
                        >
                          {/* <Translate name="HOME_FREE_TRIAL_BTN" /> */}
                          Get started in minutes
                        </Button>
                      )}
                    />
                    <img
                      src={btnArrow}
                      className="d-none d-xl-flex"
                      style={{position: 'absolute', bottom: '-60px', left: '40%'}}
                      alt=""
                    />
                  </div>

                  <Button
                    primary
                    className="software_banner_btn outline-white ml-md-3 trial_btn w-100 w-md-50"
                    onClick={openCalendly}
                  >
                    Request a demo
                  </Button>
                </div>
              </Col>
              <Col lg={6} className="d-none d-lg-flex">
                <img
                  src={dash}
                  alt="Website Change Detection, Monitoring & Archiving"
                  style={{
                    maxWidth: '100%',
                    margin: 'auto -20px auto 0',
                    zIndex: 10,
                  }}
                />
              </Col>
            </Row>
          </div>
        </div>
      </section>
      <section className="blue grey-wrapper py-4">
        <div className="container_home">
          <div className="row_home">
            <Row className="m-0">
              <Col lg={6} className="d-flex d-lg-none">
                <h2 className="f_size_38 f_700 w_color l_height50 m-auto text-center text-lg-left">
                  Trusted by over{' '}
                  <span className="blue_color">
                    130,000 <br />
                    businesses of all sizes.
                  </span>
                </h2>
              </Col>
              <Col lg={6} className="d-flex">
                <div className="m-auto d-flex flex-wrap">
                  {ratings.map(item => (
                    <div key={item.name} className="mx-auto my-4 d-flex col-6">
                      <img
                        src={item.image}
                        alt={item.name}
                        height={90}
                        width={140}
                        className="m-auto"
                        style={{
                          height: '90px',
                          width: '140px',
                        }}
                      />
                    </div>
                  ))}
                </div>
              </Col>
              <Col lg={6} className="d-none d-lg-flex">
                <h2 className="f_size_38 f_700 w_color l_height50 m-auto">
                  Trusted by over{' '}
                  <span className="blue_color">
                    130,000 <br />
                    businesses of all sizes.
                  </span>
                </h2>
              </Col>
            </Row>
          </div>
          <Row className="row_home mt-4">
            <div className="d-flex col-6 col-lg-3 p-3">
              <img src={business1} className="m-auto" alt="Airmeet" />
            </div>
            <div className="d-flex col-6 col-lg-3 p-3">
              <img src={business2} className="m-auto" alt="ManpowerGroup" />
            </div>
            <div className="d-flex col-6 col-lg-3 p-3">
              <img src={business3} className="m-auto" alt="COINMETRICS" />
            </div>
            <div className="d-flex col-6 col-lg-3 p-3">
              <img src={business4} className="m-auto" alt="RankMath" />
            </div>
          </Row>
        </div>
      </section>
    </>
  );
};

export default HomePageUpperSection;
